import ApiService from "@/core/services/ApiService";
import { Country } from "@/core/types/application/Country";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import { LocationResponse } from "@/core/types/misc/LocationResponse";
import { Option } from "@/core/types/misc/Option";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { ListData as ServiceCategory } from "@/core/types/gws-master/master/medical-services/product/ListData";
import { ListData as ServicePackage } from "@/core/types/gws-master/master/medical-services/package/ListData";
import {
  ListItem as MedicalFacilityItem,
  MedicalItem as FacilityItem,
  FacilityItemByCity,
  Operational,
} from "@/core/types/gws-master/master/medical-facility/ListItem";
import { BlkWrapper } from "@/core/types/gws-master/master/blk/BlkWrapper";
import { ListItem as BlkItem, Instructor } from "@/core/types/gws-ap2tki/ListItem";
import { ListItem as VocationalItem } from "@/core/types/gws-ap2tki/vocational/ListItem";
import { ListItem as CertificationType } from "@/core/types/gws-ap2tki/typecert/ListItem";
import { ListItem as ClassItem} from "@/core/types/gws-blk/class-info/ListItem";
import {
  formatDate,
  getDifferenceTime,
  simpleFormatDate,
  simpleFormatDateShort,
} from "@/core/helpers/date-format";
import { ListData as ConfigItem } from "@/core/types/gws-medical/product-management/ListData";
import { BlkByCityItem } from "@/core/types/gws-global-feature/cpmi/BlkByCityItem";
import { LspByCityItem } from "@/core/types/gws-global-feature/cpmi/LspByCityItem";

export const useGlobalLookupStore = defineStore({
  id: "globalLookupStore",
  state: () => {
    return {
      stateLoading: false,
      stateProvinceOption: [] as Option[],
      stateCityOption: [] as Option[],
      stateCountryOption: [] as Option[],
      stateCountryOptionId: [] as Option[],
      stateNativeCountryOption: [] as Option[],
      stateGenderOption: [] as Option[],
      stateReligionOption: [] as Option[],
      stateMaritalStatusesOption: [] as Option[],
      stateBloodTypeOption: [] as Option[],
      stateSyarikahOption: [] as Option[],
      stateCompanyOption: [] as Option[],
      stateMedicalServiceOption: [] as Option[],
      stateMedicalPackageOption: [] as Option[],
      stateMedicalPackageConfig: [] as ConfigItem[],
      stateMedicalFacilities: [] as FacilityItem[],
      stateMedicalFacilitiesByCity: [] as FacilityItemByCity[],
      stateBlkByCity: [] as BlkByCityItem[],
      stateMedicalFacilityOps: [] as Operational[],
      stateAgentsOption: [] as Option[],
      stateFacilityOpsHour: [] as string[],
      stateVocational: [] as Option[],
      stateTypeCert: [] as Option[],
      statePackageCert: [] as Option[],
      stateServicesGroup: [] as Option[],
      stateInstructor: [] as Option[],
      stateGroupParticipant: [] as Option[],
      stateLspByCity: [] as LspByCityItem[],
      stateAirportDep: [] as Option[],
      stateAirportArr: [] as Option[],
    };
  },
  getters: {
    loading(state) {
      return state.stateLoading;
    },
    province(state) {
      return state.stateProvinceOption;
    },
    city(state) {
      return state.stateCityOption;
    },
    genders(state) {
      return state.stateGenderOption;
    },
    religions(state) {
      return state.stateReligionOption;
    },
    maritalStatuses(state) {
      return state.stateMaritalStatusesOption;
    },
    bloodTypes(state) {
      return state.stateBloodTypeOption;
    },
    syarikah(state) {
      return state.stateSyarikahOption;
    },
    countries(state) {
      return state.stateCountryOption;
    },
    countriesId(state) {
      return state.stateCountryOptionId;
    },
    nativeCountries(state) {
      return state.stateNativeCountryOption;
    },
    medicalServicesCategory(state) {
      return state.stateMedicalServiceOption;
    },
    medicalPackage(state) {
      return state.stateMedicalPackageOption;
    },
    medicalFacility(state) {
      return state.stateMedicalFacilities;
    },
    medicalFacilityOps(state) {
      return state.stateMedicalFacilityOps;
    },
    blkByCity(state) {
      return state.stateBlkByCity;
    },
    facilityOpsHour(state) {
      return state.stateFacilityOpsHour;
    },
    companies(state) {
      return state.stateCompanyOption;
    },
    agents(state) {
      return state.stateAgentsOption;
    },
    packageConfig(state) {
      return state.stateMedicalPackageConfig;
    },
    vocations(state) {
      return state.stateVocational;
    },
    typecert(state) {
      return state.stateTypeCert;
    },
    packagecert(state) {
      return state.statePackageCert;
    },
    serviceGroup(state) {
      return state.stateServicesGroup;
    },
    groupInstructor(state) {
      return state.stateInstructor;
    },
    groupParticipant(state) {
      return state.stateGroupParticipant;
    },
    lspByCity(state) {
      return state.stateLspByCity;
    },
    airportsDep(state) {
      return state.stateAirportDep;
    },
    airportsArr(state) {
      return state.stateAirportArr;
    }
  },
  actions: {
    async getLookupCountryId() {
      this.stateCountryOptionId = [] as Option[];
      this.stateLoading = true;
      const params = {
        page: "1",
        limit: "999",
        dir: "ASC",
        sort: "name",
      };
      try {
        const response: AxiosResponse<ApiResponse<Country[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `countries?${new URLSearchParams(params).toString()}`
          );
        const countries = response.data.data;
        countries.forEach((el) => {
          this.stateCountryOptionId.push({
            value: el.id,
            label: el.name,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupCountry() {
      this.stateCountryOption = [] as Option[];
      this.stateLoading = true;
      const params = {
        page: "1",
        limit: "999",
        dir: "ASC",
        sort: "name",
      };
      try {
        const response: AxiosResponse<ApiResponse<Country[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `countries?${new URLSearchParams(params).toString()}`
          );
        const countries = response.data.data;
        countries.forEach((el) => {
          this.stateCountryOption.push({
            value: el.code,
            label: el.name,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupAirportDep() {
      this.stateCountryOption = [] as Option[];
      this.stateLoading = true;
      const params = {
        page: "1",
        limit: "999",
        dir: "ASC",
        sort: "name",
        country_id: "5e29a226-931f-4a3a-bb9b-13e4e05e02b9",
      };
      try {
        const response: AxiosResponse<ApiResponse<Country[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `airports?${new URLSearchParams(params).toString()}`
          );
        const countries = response.data.data;
        countries.forEach((el) => {
          this.stateCountryOption.push({
            value: el.id,
            label: `${el.name}`,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupAirport() {
      this.stateAirportArr = [] as Option[];
      this.stateLoading = true;
      const params = {
        page: "1",
        limit: "999999",
        dir: "ASC",
        sort: "name",
      };
      try {
        const response: AxiosResponse<ApiResponse<Country[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `airports?${new URLSearchParams(params).toString()}`
          );
        const countries = response.data.data;
        countries.forEach((el) => {
          this.stateAirportArr.push({
            value: el.id,
            label: `${el.name}`,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupCompany() {
      this.stateCompanyOption = [];
      const params = {
        page: "1",
        limit: "999",
        dir: "DESC",
        sort: "created_at",
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `companies?${new URLSearchParams(params).toString()}`
        );
        const listCompany = response.data.data.p3mi;
        listCompany.forEach((el) => {
          this.stateCompanyOption.push({
            label: el.name,
            value: el.id,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupAgents() {
      this.stateAgentsOption = [];
      const params = {
        page: "1",
        limit: "999",
        dir: "DESC",
        sort: "created_at",
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `agents?${new URLSearchParams(params).toString()}`
        );
        const listAgents = response.data.data.agent_p3mi;
        listAgents.forEach((el) => {
          this.stateAgentsOption.push({
            label: el.name,
            value: el.id,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupProvince(country = "") {
      this.stateProvinceOption = [];
      this.stateLoading = true;
      const params = {
        country_code: country,
      };
      try {
        const response: AxiosResponse<LocationResponse[]> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `provinces?${
              country !== "" ? new URLSearchParams(params).toString() : ""
            }`
          );
        const provinces = response.data;
        provinces.forEach((el) => {
          this.stateProvinceOption.push({
            value: el.id,
            label: el.name,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupCity(provinceId: string) {
      this.stateCityOption = [];
      this.stateLoading = true;
      try {
        const response: AxiosResponse<LocationResponse[]> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `cities/${provinceId}`
          );
        const cities = response.data;
        cities.forEach((el) => {
          this.stateCityOption.push({
            value: el.id,
            label: el.name,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupMedicalServices() {
      this.stateMedicalServiceOption = [];
      this.stateLoading = true;
      const params = {
        page: "1",
        limit: "999",
        dir: "ASC",
        sort: "name",
      };
      try {
        const response: AxiosResponse<ApiResponse<ServiceCategory[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `medical/categories?${new URLSearchParams(params).toString()}`
          );
        const serviceCat = response.data.data;
        serviceCat.forEach((el) => {
          this.stateMedicalServiceOption.push({
            value: el.id,
            label: el.name,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupMedicalPackages(id: string) {
      this.stateMedicalPackageOption = [];
      this.stateLoading = true;
      const params = {
        page: "1",
        limit: "999",
        dir: "ASC",
        sort: "name",
        category_id: id,
      };
      try {
        const response: AxiosResponse<ApiResponse<ServicePackage[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `medical/packages?${new URLSearchParams(params).toString()}`
          );
        const servicePackage = response.data.data;
        servicePackage.forEach((el) => {
          this.stateMedicalPackageOption.push({
            value: el.id,
            label: el.name,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupMedicalFacilitiesByCity(id: string, date: string) {
      this.stateMedicalFacilitiesByCity = [];
      this.stateLoading = true;
      const params = {
        days: formatDate(date, "YYYY-MM-DD"),
      };
      try {
        const response: AxiosResponse<FacilityItemByCity[]> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `medical/facilities/city/${id}?${new URLSearchParams(
              params
            ).toString()}`
          );
        this.stateMedicalFacilitiesByCity = response.data;
      } catch (error: any) {
        console.log(error);
      }
    },
    async getLookupBlkByCity(id: string, vocational_id: string, date: string) {
      this.stateBlkByCity = [];
      this.stateLoading = true;
      const params = {
        date: formatDate(date, "YYYY-MM-DD"),
      };
      try {
        const response: AxiosResponse<BlkByCityItem[]> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `blk/city/${id}/vocational/${vocational_id}?${new URLSearchParams(
              params
            ).toString()}`
          );
        const blkList = response.data;
        blkList.forEach((el) => {
          if(el.blk_quota.available_quota) {
            this.stateBlkByCity.push(el);
          }
        })
      } catch (error: any) {
        console.log(error);
      }
    },
    async getLookupLspByCity(id: string, date: string) {
      this.stateLspByCity = [];
      this.stateLoading = true;
      const params = {
        date: formatDate(date, "YYYY-MM-DD"),
      };
      try {
        const response: AxiosResponse<LspByCityItem[]> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `lsp/city/${id}?${new URLSearchParams(
              params
            ).toString()}`
          );
        const lspList = response.data;
        lspList.forEach((el) => {
          if(el.lsp_quota.available_quota) {
            this.stateLspByCity.push(el);
          }
        })
      } catch (error: any) {
        console.log(error);
      }
    },
    async getLookupFacilityOperational(id: string, day: string) {
      this.stateMedicalFacilityOps = [];
      this.stateLoading = true;
      const params = {
        page: "1",
        limit: "999",
        dir: "ASC",
        sort: "created_at",
      };
      try {
        const response: AxiosResponse<ApiResponse<Operational[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `medical/facilities/${id}/operational?${new URLSearchParams(
              params
            ).toString()}`
          );
        this.stateMedicalFacilityOps = response.data.data;
        const selected = this.stateMedicalFacilityOps.filter((el) => {
          return el.days.includes(day);
        }) as Operational[];
        if (selected) {
          this.stateFacilityOpsHour = getDifferenceTime(
            selected[0].start_hour,
            selected[0].end_hour
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupMedicalFacilities() {
      this.stateMedicalFacilities = [];
      this.stateLoading = true;
      const params = {
        page: "1",
        limit: "999",
        dir: "ASC",
        sort: "name",
      };
      try {
        const response: AxiosResponse<ApiResponse<MedicalFacilityItem>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `medical/facilities?${new URLSearchParams(params).toString()}`
          );
        this.stateMedicalFacilities = response.data.data.medical;
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupPackageConfiguration(category: string, facility: string) {
      this.stateMedicalPackageConfig = [];
      this.stateLoading = true;
      const params = {
        page: "1",
        limit: "999",
        dir: "ASC",
        sort: "name",
        facility_id: facility,
      };
      try {
        const response: AxiosResponse<ApiResponse<ConfigItem[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `medical/packages/${category}/configuration?${new URLSearchParams(
              params
            ).toString()}`
          );
        this.stateMedicalPackageConfig = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupVocation() {
      this.stateVocational = [];
      this.stateLoading = true;
      const params = {
        page: "1",
        limit: "999",
        dir: "ASC",
        sort: "name",
      };
      try {
        const response: AxiosResponse<ApiResponse<VocationalItem[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `vocational?${new URLSearchParams(params).toString()}`
          );
        const vocations = response.data.data;
        vocations.forEach((el) => {
          this.stateVocational.push({
            value: el.id,
            label: el.name,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupCertificationType() {
      this.stateTypeCert = [];
      this.stateLoading = true;
      const params = {
        page: "1",
        limit: "999",
        dir: "ASC",
        sort: "name",
      };
      try {
        const response: AxiosResponse<ApiResponse<VocationalItem[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `certificate-type-blk?${new URLSearchParams(params).toString()}`
          );
        const typec = response.data.data;
        typec.forEach((el) => {
          this.stateTypeCert.push({
            value: el.id,
            label: el.name,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupCertificationPackage(typeId: string) {
      this.statePackageCert = [];
      this.stateLoading = true;
      const params = {
        page: "1",
        limit: "999",
        dir: "ASC",
        sort: "name",
        certificate_type_id: typeId,
      };
      try {
        const response: AxiosResponse<ApiResponse<VocationalItem[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `certificate-package-blk?${new URLSearchParams(params).toString()}`
          );
        const typec = response.data.data;
        typec.forEach((el) => {
          this.statePackageCert.push({
            value: el.id,
            label: el.name,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupServiceGroups(vocationId: string, blkId: string) {
      this.stateServicesGroup = [];
      this.stateLoading = true;
      const params = {
        page: "1",
        limit: "999",
        dir: "ASC",
        sort: "name",
        vocational_id: vocationId,
      };
      try {
        const response: AxiosResponse<ApiResponse<ClassItem[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `blk/${blkId}/services-groups-blk?${new URLSearchParams(
              params
            ).toString()}`
          );
        const typec = response.data.data;
        typec.forEach((el) => {
          this.stateServicesGroup.push({
            value: el.services_groups_blk_id,
            label: el.name,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupInstructor(blkId: string) {
      this.stateInstructor = [];
      this.stateLoading = true;
      const params = {
        page: "1",
        limit: "999",
        dir: "ASC",
        sort: "name",
      };
      try {
        const response: AxiosResponse<ApiResponse<any[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `blk/${blkId}/instructor-blk?${new URLSearchParams(
              params
            ).toString()}`
          );
        const typec = response.data.data;
        typec.forEach((el) => {
          this.stateInstructor.push({
            value: el.id,
            label: el.name,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupGroupParticipant(serviceGroupBlkId:string, blkId: string) {
      this.stateGroupParticipant = [];
      this.stateLoading = true;
      const params = {
        page: "1",
        limit: "999",
        dir: "ASC",
        sort: "name",
      };
      try {
        const response: AxiosResponse<ApiResponse<any[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `group-practice-monitoring/${serviceGroupBlkId}/${blkId}/participants?${new URLSearchParams(
              params
            ).toString()}`
          );
        const typec = response.data.data;
        typec.forEach((el) => {
          this.stateGroupParticipant.push({
            value: el.cpmi_id,
            label: el.cpmi_name,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    getLookupGender() {
      this.stateGenderOption = [
        {
          value: "MALE",
          label: "Laki-laki",
        },
        {
          value: "FEMALE",
          label: "Perempuan",
        },
      ];
    },
    getMaritalStatuses() {
      this.stateMaritalStatusesOption = [
        {
          value: "KAWIN",
          label: "Kawin",
        },
        {
          value: "TIDAK_KAWIN",
          label: "Tidak Kawin",
        },
        {
          value: "CERAI_MATI",
          label: "Cerai Mati",
        },
        {
          value: "CERAI_HIDUP",
          label: "Cerai Hidup",
        },
      ];
    },
    getReligions() {
      this.stateReligionOption = [
        {
          value: "ISLAM",
          label: "Islam",
        },
        {
          value: "KATOLIK",
          label: "Katolik",
        },
        {
          value: "PROTESTAN",
          label: "Protestan",
        },
        {
          value: "HINDU",
          label: "Hindu",
        },
        {
          value: "BUDDHA",
          label: "Buddha",
        },
        {
          value: "KHONGHUCU",
          label: "Khonghucu",
        },
      ];
    },
    getBloodTypes() {
      this.stateBloodTypeOption = [
        {
          value: "A",
          label: "A",
        },
        {
          value: "B",
          label: "B",
        },
        {
          value: "AB",
          label: "AB",
        },
        {
          value: "O",
          label: "O",
        },
      ];
    },
    getCountries() {
      this.stateNativeCountryOption = [
        {
          value: "ID",
          label: "Indonesia",
        },
      ];
    },
  },
});
